import React, { useState, useRef, useCallback } from 'react'
import ReactPlayer from 'react-player'
import styled from '@emotion/styled'
import ScrollPercentage from 'react-scroll-percentage/dist'
import Img from 'gatsby-image'

import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ScrollListener from 'src/components/ScrollListener'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

import { colors, animations, util, mq } from 'src/styles'
import { rgba } from 'polished'


const Wrapper = styled.div`
  position: relative;
  transition: opacity ${ animations.oneSpeed } ease-in-out, transform ${ animations.oneSpeed } ease-in-out;
  ${ ({ visible }) => visible === true ? `
    opacity: 1;
  ` : `
    opacity: 0.2;
  ` }

  ${ ({ delay }) => delay > 0 ? `
    transition-delay: 200ms;
  ` : `` }

  ${ ({ percentage }) => percentage == 0 ? `
    transform: none;
  ` : `` }

  ${ ({ percentage }) => percentage < 0.01 ? `
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  ` : `` }

  ${ ({ percentage }) => percentage > 0.01 && percentage < 0.99 ? `
    // transform: scale(0.9);
  ` : `` }

  ${ ({ percentage }) => percentage > 0.99 ? `
    transform: translate3d(0, -80px, 0);
    opacity: 0;
  ` : `` }

  &:hover {
    .video-cover-image {
      transform: scale(1.025);
      transition-delay: 0ms !important;
    }
  }
	${ mq.smallAndBelow } {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  // ${ util.responsiveStyles('border-width', 80, 60, 40, 20) };
  // ${ util.responsiveStyles('padding', 50, 30, 26, 20) };
  
  ${ mq.mediumAndBelow } {
  
  }
  .video-cover-image {
    border-radius: 10px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgb(25, 25, 25, 0.5) 100%);
`

const OverlayImgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 10px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  .video-cover-image {
    transition: transform ${ animations.slowSpeed } ease-in-out; 
  }
`


const MediaResponsiveImage = styled(Image)`
  ${ ({ setHeight }) => setHeight !== 'auto' ? `
    height: ${ setHeight };
    > div {
      height: ${ setHeight };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
  border-radius: 20px;
`

const ProjectInfoWrapper = styled.div`
	padding-top: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
  position: relative;
  z-index: 2;
  .h2 {
    &:hover {
      cursor: pointer;
    }
  }
	.h3 {
		margin-left: 7px;
    &:hover {
      cursor: pointer;
    }
	}
  svg {
    display: block;
    width: 30px;
    height: auto;
    * {
      fill: currentcolor;
    }
    ${ mq.smallAndBelow } {
      width: 24px;
      height: auto;
    }
  }
  &.playing {
    z-index: 4;
  }
`

const InfoTrigger = styled.div`
  cursor: pointer;
  svg {
    transition: transform ${ animations.oneSpeed } ease-in-out;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: ${ 9 / 16 * 100 }%;
  // background-color: ${ colors.textColor };
  border-radius: 10px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  &.playing {
    z-index: 5;
  }
  &:hover {
    cursor: pointer;
  }
`

const StyledVideo = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
  > div {
    border-radius: 10px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const VideoPlayingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.9);
  visibility: hidden;
  opacity: 0;
  transition: visibility ${ animations.oneSpeed } ease-in-out, opacity ${ animations.oneSpeed } ease-in-out;
  &.playing {
    visibility: visible;
    opacity: 1;
  }

`


const ProjectItem = ({ className, project, handleModalTrigger, filtered, filters, handlePlay, delay, debug }) => {
  let visible = true

  if (filtered === true) {
    const projectServices = project.services.map((service, index) => {
      return (service.slug)
    })
    const intersection = projectServices.filter(service => filters.includes(service))

    if (intersection.length < 1) {
      visible = false
    }
  }

  const [ coverImage, toggleCoverImage ] = useState(project.coverImage.image.fluid)
  // const [ playing, togglePlaying ] = useState(true)

  const [ videoPlaying, toggleVideoPlaying ] = useState(false)

  const [ scrollPosition, captureScrollPosition ] = useState(0)

  // console.log("videoPlaying::", videoPlaying)

  const videoEl = useRef(null)
  const projectInfoEl = useRef(null)
  const videoOverlay = useRef(null)

  const updateScrollPosition = useCallback(
      ( scrollY, ref ) => {
        console.log(' updateScrollPosition ::::', scrollY, ref)
        captureScrollPosition(scrollY);
        },
      [],
  );

  const complexPlay = useCallback(
    ( scrollPosition, videoPlaying, percentage ) => {
      if ( debug === true ) {
        console.log('scrollPosition::', scrollPosition)
        console.log('percentage::', percentage)
      }
      if ( videoPlaying ) {
        if ( scrollPosition != 0 && Math.abs(scrollPosition - percentage) > 0.15 ) {
          toggleVideoPlaying(false)
          return ( videoPlaying )
        } else {
          return videoPlaying
        }
      } else {
        return ( videoPlaying )
      }
    },
    [],
  )

  //  const coverTimeout = useCallback(
  //   () => {
  //     setTimeout(() => {
  //       console.log('trigger coverImage')
  //     }, 3000);
  //   },
  //   [],
  // )

  // ... use Effect to track videoPlaying, coverImage -> conditional to delay coverImage rip out to prevent "blackout" before video play


  // console.log('coverImage state::::', coverImage)
  // console.log('percentage::', percentage)

  return (

    <ScrollListener.Consumer>
        {({
          scrolledToTop,
          scrolledToBottom,
          scrollY,
          scrolledUp,
          hasScrolled,
          pageHeight
        }) => {

          return (

            <ResponsiveComponent
              small={
                <Wrapper className='video-item' visible={visible} transform='translate3d(0, 80px, 0)' delay={delay} speed={750}>
                  <VideoWrapper ref={videoEl}>
                    <StyledVideo
                      url={'https://vimeo.com/' + project.videoId}
                      playing={videoPlaying}
                      loop={true}
                      muted={false}
                      autoPlay={false}
                      playIcon={<span />}
                      controls={false}
                      light={false}
                      // onPlay={() => {videoEl.current.classList.add('playing'); videoOverlay.current.classList.add('playing'); toggleVideoPlaying(true); handlePlay(); }}
                      // onPause={() => {videoEl.current.classList.remove('playing'); videoOverlay.current.classList.remove('playing'); toggleVideoPlaying(false); toggleCoverImage(project.coverImage.image.fluid.src); handlePlay();}}
                      onPause={() => {toggleCoverImage(project.coverImage.image.fluid);}}
                      config={{
                        vimeo: {
                          color: 'white',
                          playerOptions: { playsinline: 0, controls: false }
                        }
                      }}
                    />

                    { coverImage !== false && (
                      <Overlay
                        onClick={() =>{toggleCoverImage(false); toggleVideoPlaying(true);}}
                      >  
                        <ImageOverlay />
                        <OverlayImgWrap>
                        <Img
                          fluid={coverImage}
                          loading='eager'
                          className='video-cover-image'
                        />
                        </OverlayImgWrap>
                      </Overlay>
                    )}
                  </VideoWrapper>
                
                  <ProjectInfoWrapper ref={projectInfoEl}>
                    <div>
                      <span className="h2" onClick={() => { toggleCoverImage(false); toggleVideoPlaying(true); }}>{project.client}</span><span className="h3" onClick={() => { toggleCoverImage(false); toggleVideoPlaying(true); }}>{project.projectTitle}</span>
                      </div>
                      <InfoTrigger onClick={() => handleModalTrigger('project', project)}>
                        <svg viewBox="0 0 26 26">
                          <path d="M13,0C5.82,0,0,5.82,0,13c0,7.18,5.82,13,13,13s13-5.82,13-13C26,5.82,20.18,0,13,0z M13,25C6.383,25,1,19.617,1,13
                          S6.383,1,13,1s12,5.383,12,12S19.617,25,13,25z M12.369,10.476h1.262V18.5h-1.262V10.476z M12.24,7.5h1.521v1.585H12.24V7.5z"/>
                        </svg>
                      </InfoTrigger>
                  </ProjectInfoWrapper>
                  <VideoPlayingOverlay ref={videoOverlay} onClick={() => { toggleVideoPlaying(false); videoOverlay.current.classList.remove('playing') }}/>
                </Wrapper>  
              }
              medium={
                <ScrollPercentage threshold={0} rootMargin={'-20px 0'}>
                  {({ percentage, ref, entry }) => { 
                  const barSize = (1 - percentage).toPrecision(3)
                  if ( debug === true ) {
                  }
                  let percentagePlay;
                  if ( percentage > 0.9 || percentage < 0.01 ) {
                    toggleVideoPlaying(false)
                  } 
                  // console.log('project.client::', project.client)
                  // console.log('percentage::', percentage)
                  
                  return (
                      <Wrapper ref={ref} className='video-item' visible={visible} delay={delay} speed={750} percentage={percentage}>
                        <VideoWrapper ref={videoEl} className="video-wrapper">
                          <StyledVideo
                            url={'https://vimeo.com/' + project.videoId}
                            playing={complexPlay(scrollPosition, videoPlaying, percentage)}
                            loop={true}
                            muted={false}
                            autoPlay={false}
                            playIcon={<span />}
                            controls={true}
                            light={coverImage.src}
                            onPlay={() => {videoEl.current.classList.add('playing'); videoOverlay.current.classList.add('playing'); projectInfoEl.current.classList.add('playing'); toggleVideoPlaying(true); handlePlay(); updateScrollPosition(percentage, videoEl.current); }}
                            onPause={() => {videoEl.current.classList.remove('playing'); videoOverlay.current.classList.remove('playing'); projectInfoEl.current.classList.remove('playing'); toggleVideoPlaying(false); toggleCoverImage(project.coverImage.image.fluid); handlePlay(); captureScrollPosition(0);}}
                            config={{
                              vimeo: {
                                // background: true,
                                color: 'white',
                                playsinline: true,
                                // controls: false,
                                playerOptions: { controls: false }
                              }
                            }}
                          />
                          { coverImage !== false && (
                            <Overlay
                              onClick={() =>{toggleCoverImage(false); toggleVideoPlaying(true)}}
                            >  
                              <ImageOverlay />
                              <OverlayImgWrap>
                              <Img
                                fluid={coverImage}
                                loading='eager'
                                className='video-cover-image'
                              />
                              </OverlayImgWrap>
                            </Overlay>
                          )}

                        </VideoWrapper>
                      
                        <ProjectInfoWrapper ref={projectInfoEl}>
                          <div>
                            <span className="h2" onClick={() => { toggleCoverImage(false); toggleVideoPlaying(true); }}>{project.client}</span><span className="h3" onClick={() => { toggleCoverImage(false); toggleVideoPlaying(true); }}>{project.projectTitle}</span>
                            </div>
                            <InfoTrigger onClick={() => handleModalTrigger('project', project)}>
                              <svg viewBox="0 0 26 26">
                                <path d="M13,0C5.82,0,0,5.82,0,13c0,7.18,5.82,13,13,13s13-5.82,13-13C26,5.82,20.18,0,13,0z M13,25C6.383,25,1,19.617,1,13
                                S6.383,1,13,1s12,5.383,12,12S19.617,25,13,25z M12.369,10.476h1.262V18.5h-1.262V10.476z M12.24,7.5h1.521v1.585H12.24V7.5z"/>
                              </svg>
                            </InfoTrigger>
                        </ProjectInfoWrapper>
                        <VideoPlayingOverlay ref={videoOverlay} onClick={() => { toggleVideoPlaying(false); videoOverlay.current.classList.remove('playing') }}/>
                      </Wrapper>  
                    )
                  }}
                
                

                </ScrollPercentage>


              }
            />

            
          )
        }}
    </ScrollListener.Consumer>

    
  )
}

export default ProjectItem
